import React, { useState } from "react";
import PropTypes from "prop-types";

import { ReactComponent as EyeIcon } from "assets/icons/eyeIcon.svg";
import { ReactComponent as EyeIconOff } from "assets/icons/eyeIconOff.svg";
import { FormInput } from "components/FormInput/FormInput";
import { StrengthIndicator } from "./StrengthIndicator";

import styles from "./PasswordInput.module.scss";

export const PasswordInput = ({ isStrengthEnabled, ...otherProps }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((isHidden) => !isHidden);
  };

  return (
    <div className={styles.passwordInputWrapper}>
      <div className={styles.passwordInputInner}>
        <FormInput
          {...otherProps}
          type={showPassword ? "text" : "password"}
          wrapperClassName={styles.passwordInput}
        />
        {showPassword ? (
          <EyeIcon onClick={toggleShowPassword} className={styles.eyeIcon} />
        ) : (
          <EyeIconOff onClick={toggleShowPassword} className={styles.eyeIcon} />
        )}
      </div>
      {isStrengthEnabled && <StrengthIndicator password={otherProps.value} />}
    </div>
  );
};

PasswordInput.propTypes = {
  isStrengthEnabled: PropTypes.bool,
};
PasswordInput.defaultProps = {
  isStrengthEnabled: false,
};
