export function connect(apiSlice) {
  apiSlice.injectEndpoints({
    overrideExisting: false,
    keepUnusedDataFor: 0,
    endpoints: (build) => ({
      createVehicleApplications: build.mutation({
        query: ({ driverId, data }) => ({
          url: `drivers/${driverId}/vehicle-applications`,
          method: "POST",
          data,
        }),
      }),
      getVehicleApplications: build.query({
        query: ({ driverId }) => ({
          url: `drivers/${driverId}/vehicle-applications`,
        }),
      }),
      updateVehicleApplications: build.mutation({
        query: ({ vehicleApplicationId, data }) => ({
          method: "PATCH",
          url: `vehicle-applications/${vehicleApplicationId}`,
          data,
        }),
      }),
    }),
  });
}
