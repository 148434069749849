import { forwardRef } from "react";
import { InputNumber } from "antd";
import "./NumberInput.scss";

export const NumberInput = forwardRef(function InputInternal(
  {
    currency = "£",
    min = 0,
    max = 40,
    value = min,
    onChange = () => {},
    formatter = (value) => `${currency} ${value}`,
    ...otherProps
  },
  ref,
) {
  return (
    <InputNumber
      {...otherProps}
      min={min}
      max={max}
      defaultValue={value}
      onChange={onChange}
      formatter={formatter}
      className="numberInput"
      ref={ref}
    />
  );
});
