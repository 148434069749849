import { isBefore } from "date-fns";
import { useTranslation } from "react-i18next";

import { Field } from "components/Field";
import { Input } from "components/Input";
import { DatePicker } from "components/DatePicker";

import styles from "./VehicleDetails.module.scss";

export function ManualLookupServiceFieldset({ isEditPage = true, data, errors = {}, onChange }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.row}>
        <Field
          htmlFor="registration"
          label={t("field.vehicleRegistration")}
          error={errors.registration}
        >
          <Input
            disabled={!isEditPage}
            id="registration"
            name="registration"
            value={data.registration}
            onChange={(event) => {
              onChange("registration", event.target.value.toUpperCase());
            }}
            invalid={errors.registration !== undefined}
          />
        </Field>

        <Field
          htmlFor="year"
          label={t("field.registrationYear")}
          error={errors.year}
        >
          <Input
            id="year"
            name="year"
            type="number"
            disabled={!isEditPage}
            value={data.year}
            onChange={(event) => {
              onChange("year", event.target.value);
            }}
            invalid={errors.year !== undefined}
          />
        </Field>
      </div>

      <div className={styles.row}>
        <Field
          label={t("field.vehicleMake")}
          htmlFor="make"
          error={errors.make}
        >
          <Input
            id="make"
            name="make"
            disabled={!isEditPage}
            value={data.make}
            onChange={(event) => {
              onChange("make", event.target.value);
            }}
            invalid={errors.make !== undefined}
          />
        </Field>

        <Field
          label={t("field.vehicleModel")}
          htmlFor="model"
          error={errors.model}
        >
          <Input
            id="model"
            name="model"
            disabled={!isEditPage}
            value={data.model}
            onChange={(event) => {
              onChange("model", event.target.value);
            }}
            invalid={errors.model !== undefined}
          />
        </Field>
      </div>

      <div className={styles.row}>
        <Field label={t("field.colour")} htmlFor="colour" error={errors.colour}>
          <Input
            id="colour"
            name="colour"
            disabled={!isEditPage}
            value={data.colour}
            onChange={(event) => {
              onChange("colour", event.target.value);
            }}
            invalid={errors.colour !== undefined}
          />
        </Field>

        <Field
          label={t("field.co2Emissions")}
          htmlFor="emissions"
          error={errors.emissions}
        >
          <Input
            id="emissions"
            name="emissions"
            disabled={!isEditPage}
            value={data.emissions}
            onChange={(event) => {
              onChange("emissions", event.target.value);
            }}
            invalid={errors.emissions !== undefined}
          />
        </Field>
      </div>

      <div className={styles.row}>
        <Field
          label={t("field.nctExpiryDate")}
          htmlFor="nctExpiryDate"
          error={errors.nctExpiry}
        >
          <DatePicker
            id="nctExpiryDate"
            getPopupContainer={(trigger) => trigger.parentNode}
            value={data.nctExpiry}
            disabled={!isEditPage}
            disabledDate={(current) => {
              return isBefore(new Date(current), new Date());
            }}
            onChange={(date) => {
              onChange("nctExpiry", date);
            }}
            invalid={errors.nctExpiry !== undefined}
          />
        </Field>

        <Field
          label={t("field.taxExpiryDate")}
          htmlFor="taxExpiryDate"
          error={errors.roadTaxExpiry}
        >
          <DatePicker
            id="taxExpiryDate"
            getPopupContainer={(trigger) => trigger.parentNode}
            value={data.roadTaxExpiry}
            disabled={!isEditPage}
            disabledDate={(current) => {
              return isBefore(new Date(current), new Date());
            }}
            onChange={(date) => {
              onChange("roadTaxExpiry", date);
            }}
            invalid={errors.roadTaxExpiry !== undefined}
          />
        </Field>
      </div>
    </div>
  );
}
