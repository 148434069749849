import { paths as userPaths } from "./endpoints/users";
import { paths as fleetsPath } from "./endpoints/fleets";
import { getReactAppBaseUrl } from "mocks/env";

export { api } from "./apiSlice";
export { axiosBaseQuery, axiosInstance } from "./axiosBaseQuery";
export { selectDriverProfilesFromGetMeResult } from "./endpoints/users";
export { formatDate as formatPayoutDateForAPI } from "./endpoints/payouts";

export const paths = {
  userPaths,
  fleetsPath,
};

export function getApiUrlByPath(path = "", base = getReactAppBaseUrl()) {
  const url = new URL(base);
  const newPath = path.replace(/(^\/)+|(\/$)+/g, "");
  const slashIfNeeded = url.pathname.at(-1) === "/" ? "" : "/";
  url.pathname += slashIfNeeded + newPath;

  return String(url);
}

export const DriverApplicationStatus = Object.freeze({
  /*
   * Created but not submitted.
   */
  IN_PROGRESS: "in_progress",
  /**
   * Submitted but not approved.
   */
  NEW: "new",
  APPROVED: "approved",
  REJECTED: "rejected",
});

export const UserRoles = Object.freeze({
  DRIVER: "driver",
  FLEET_ADMIN: "fleet_admin",
  FLEET_ADMIN_READONLY: "fleet_admin.read_only",
  SUPER_ADMIN: "super_admin",
  SUPPORT: "support",
});

export const BookingPaymentType = Object.freeze({
  CARD: "CARD",
  CASH: "CASH",
  INVOICE: "INVOICE",
  NA: "NA",
});

export const BookingPayoutStatus = Object.freeze({
  APPROVED: "approved",
  CHARGEBACK: "chargeback",
  DECLINED: "declined",
  PAID: "paid",
  PENDING: "pending",
  REFUND: "refund",
  FAILED: "failed",
  TRANSIT: "in_transit",
  CANCELED: "canceled ",
});

export const BadgeType = Object.freeze({
  HACKNEY: "HACKNEY",
  PRIVATE_HIRE: "PRIVATE_HIRE",
  ALL: "ALL",
});

export const PayoutMethod = Object.freeze({
  STANDARD: "standard",
  INSTANT: "instant",
});
