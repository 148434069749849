import { Link, useParams } from "react-router-dom";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { useAuth } from "hooks/useAuth";
import { routes } from "routes";
import {
  // DocumentType,
  useQueryCustomDocumentTypes,
  getLatestDocumentByStatusByType,
} from "pages/documents";
import { ReactComponent as Delimited } from "assets/icons/Sidebar/arrowDown.svg";
import { ReactComponent as CheckIcon } from "assets/icons/checkCurrentColor.svg";
import { ReactComponent as XIcon } from "assets/icons/xIconCurrentColor.svg";

import styles from "./Breadcrumbs.module.scss";
import { useSelectedFleet } from "hooks/useSelectedFleet";

const STATUS_TYPE = {
  regular: "regular",
  completed: "completed",
  rejected: "rejected",
};

// function getStatus({ type, isApplicationSubmitted, isApplicationRejected }) {
//   if (type === "submit" && isApplicationRejected) {
//     return "rejected";
//   }

//   if (type === "submit" && isApplicationSubmitted) {
//     return "completed";
//   }

//   if (statuses[type] === "disapproved") {
//     return "rejected";
//   }

//   if (completes[type]) {
//     return "completed";
//   }

//   return "regular";
// }

export function Breadcrumbs({ spaceBetween = false }) {
  const { documentTypeId } = useParams();
  const { t } = useTranslation();

  const { selectedFleetId: fleetId } = useSelectedFleet();

  // const currentDriverApplication = useMemo(() => {
  //   return user?.driver_applications?.find(
  //     (driverApplication) => driverApplication?.fleet_id === fleetId,
  //   );
  // }, [user, fleetId]);

  const {
    predefinedVehicleDocumentTypes,
    customApplicationVehicleDocumentTypes,
    // documentTypes,
  } = useQueryCustomDocumentTypes({ currentCompanyId: fleetId });

  // const completes = useBreadcrumbsCompletes({
  //   documentTypes,
  //   currentDriverApplication,
  // });
  // const statuses = useBreadcrumbsStatuses({ documentTypes });

  // const isApplicationSubmitted =
  //   typeof currentDriverApplication?.status === "string" &&
  //   currentDriverApplication.status !== "in_progress";

  // const isApplicationRejected = currentDriverApplication?.status === "rejected";

  return (
    <ul className={clsx(styles.list, { [styles.spaceBetween]: spaceBetween })}>
      {predefinedVehicleDocumentTypes.map((documentType, index) => {
        return (
          <li key={documentType.id} className={styles.item}>
            <Breadcrumb
              to={routes.addNewVehicle.getURL({
                documentTypeId: documentType.id,
              })}
              number={index + 1}
              current={documentTypeId === String(documentType.id)}
              status={STATUS_TYPE.regular}
            >
              {documentType.name}
            </Breadcrumb>

            <Delimited className={styles.delimiter} />
          </li>
        );
      })}

      <li className={styles.item}>
        <Breadcrumb
          to={routes.addNewVehicle.getURL({
            documentTypeId: "submit",
          })}
          number={
            predefinedVehicleDocumentTypes.length +
            customApplicationVehicleDocumentTypes.length +
            1
          }
          current={documentTypeId === "submit"}
          status={STATUS_TYPE.regular}
        >
          {t("action.submit")}
        </Breadcrumb>
      </li>
    </ul>
  );
}

function Breadcrumb({
  to,
  children,
  number,
  current = false,
  restricted = false,
  status,
}) {
  if (restricted) {
    return (
      <div className={styles.breadcrumb}>
        <BreadcrumbCoin status={status} current={current}>
          {number}
        </BreadcrumbCoin>
        <div
          className={clsx(styles.breadcrumbText, { [styles.current]: current })}
        >
          {children}
        </div>
      </div>
    );
  }

  return (
    <Link to={to} className={styles.breadcrumb}>
      <BreadcrumbCoin status={status} current={current}>
        {number}
      </BreadcrumbCoin>
      <div
        className={clsx(styles.breadcrumbText, { [styles.current]: current })}
      >
        {children}
      </div>
    </Link>
  );
}

function BreadcrumbCoin({ status = STATUS_TYPE.regular, current, children }) {
  const regular = status === STATUS_TYPE.regular;
  const completed = status === STATUS_TYPE.completed;
  const rejected = status === STATUS_TYPE.rejected;

  return (
    <div
      className={clsx(styles.coin, {
        [styles.regular]: regular,
        [styles.current]: current,
        [styles.completed]: completed,
        [styles.rejected]: rejected,
      })}
    >
      {completed ? <CheckIcon className={styles.checkIcon} /> : null}
      {rejected ? <XIcon className={styles.xIcon} /> : null}
      {regular ? children : null}
    </div>
  );
}

// function useBreadcrumbsCompletes({
//   documentTypes,
//   currentDriverApplication,
// } = {}) {
//   const latestDocumentByStatusByType = useLatestDocumentByStatusByType();

//   if (!Array.isArray(documentTypes)) return {};

//   function getComplete(type) {
//     if (type === DocumentType.VEHICLE_DETAILS) {
//       return (
//         typeof currentDriverApplication?.metadata?.has_own_vehicle === "boolean"
//       );
//     }
//     return Boolean(latestDocumentByStatusByType?.[type]?.last);
//   }

//   return Object.fromEntries(
//     documentTypes.map((type) => {
//       return [type.id, getComplete(type.id)];
//     }),
//   );
// }

// function useBreadcrumbsStatuses({ documentTypes } = {}) {
//   const latestDocumentByStatusByType = useLatestDocumentByStatusByType();

//   if (!Array.isArray(documentTypes)) return {};

//   function getStatus(type) {
//     return latestDocumentByStatusByType?.[type]?.last?.status;
//   }

//   return Object.fromEntries(
//     documentTypes.map((type) => {
//       return [type.id, getStatus(type.id)];
//     }),
//   );
// }

export function useLatestDocumentByStatusByType() {
  const driverApplication = useCurrentDriverApplication();

  return getLatestDocumentByStatusByType(driverApplication?.documents);
}

export function useCurrentDriverApplication() {
  const { selectedFleetId: fleetId } = useSelectedFleet();

  const { user } = useAuth();

  return (user?.driver_applications ?? []).find(
    (application) => application.fleet_id === Number(fleetId),
  );
}
