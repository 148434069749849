import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { SingleScreen } from "components/SingleScreen";

import { Breadcrumbs as AddNewVehicleBreadcrumbs } from "../Breadcrumbs/Breadcrumbs";

import styles from "./WrapperScreen.module.scss";

export const WrapperScreen = ({
  children,
  breadcrumbs = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className={clsx(styles.screenWrapper)}>
      <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>
          <div className={styles.titleWrapper}>
            <p className={styles.title}>
              {t("pageAddNewVehicle.addVehicle")}
            </p>
          </div>
          {breadcrumbs ? <AddNewVehicleBreadcrumbs /> : null}
        </div>
        <SingleScreen wrapperClassName={clsx(styles.singleScreenWrapper)}>
          <div className={styles.fullContainer}>{children}</div>
        </SingleScreen>
      </div>
    </div>
  );
};
