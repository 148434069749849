import React from "react";
import styles from "./StrengthIndicator.module.scss";
import clsx from "clsx";

export const getStrengthLabel = (value = 0) => {
  if (value === 1) return "weak";
  if (value === 2) return "weak";
  if (value === 3) return "medium";
  if (value === 4) return "strong";
  return "";
};

export const checkPasswordStrength = (password) => {
  const eightNoSpace = /^\S{8,}$/g;
  const upperCase = /^(?=.*[A-Z]).+/g;
  const oneDigit = /^(?=.*\d).+$/g;
  const specialSymbols = /[^\w\s]+/g;
  let strength = 0;

  strength += Number(eightNoSpace.test(password));
  strength += Number(upperCase.test(password));
  strength += Number(oneDigit.test(password));
  strength += Number(specialSymbols.test(password));

  return strength;
};

export const StrengthIndicator = ({ password = "" }) => {
  const strengthValue = checkPasswordStrength(password);

  return (
    <div className={styles.passwordStrengthIndicator}>
      <div className={styles.strengthMeter}>
        <div
          className={clsx(
            styles.strengthBar,
            styles[`strengthBar-${strengthValue}`],
          )}
        ></div>
        <div className={styles.strengthLabel}>
          {getStrengthLabel(strengthValue)}
        </div>
      </div>
    </div>
  );
};
