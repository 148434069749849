export const getValidationErrors = (form, t) => {
  const errors = {};

  if (form?.registration?.length === 0) {
    errors.registration = t("error.fieldShouldNotBeEmpty");
  }

  if (form?.vehicleLookupService !== "manual") {
    return errors;
  }

  if (form?.year?.length === 0) {
    errors.year = t("error.fieldShouldNotBeEmpty");
  }

  if (form?.nctExpiry === undefined || form?.nctExpiry === null) {
    errors.nctExpiry = t("error.fieldShouldNotBeEmpty");
  }

  if (form?.roadTaxExpiry === undefined || form?.roadTaxExpiry === null) {
    errors.roadTaxExpiry = t("error.fieldShouldNotBeEmpty");
  }

  return errors;
};

export const initializeVehicleDocument = (data = {}) => ({
  registration: data?.vehicle_registration || "",
  year: data?.manual_vehicle_look_up?.year || "",
  make: data?.manual_vehicle_look_up?.vehicle_make || "",
  model: data?.manual_vehicle_look_up?.vehicle_model || "",
  colour: data?.manual_vehicle_look_up?.colour || "",
  emissions: data?.manual_vehicle_look_up?.co2_emissions || "",
  nctExpiry: data?.manual_vehicle_look_up?.nct_expiry || "",
  roadTaxExpiry: data?.manual_vehicle_look_up?.road_tax_expiry || "",
  area: data?.preferred_work_area || "",
});
