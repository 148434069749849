import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  registrationNumberToReplace: null,
  vehicleApplicationId: null,
  vehicleApplicationFormData: {},
};

export const addNewVehicleSlice = createSlice({
  name: "addNewVehicleSlice",
  initialState,
  reducers: {
    setNewVehicleRegistratioinNumber: (state, action) => {
      state.registrationNumberToReplace = action.payload;
    },
    setVehicleApplicationId: (state, action) => {
      state.vehicleApplicationId = action.payload;
    },
    setVehicleApplicationFormData: (store, action) => {
      store.vehicleApplicationFormData[action.payload.documentType] =
        action.payload.data;
    },
    clearVehicleApplicationFormData: (store, action) => {
      if(action.payload === 'all') {
        store.vehicleApplicationFormData = {};
      } else {
        delete store.vehicleApplicationFormData[action.payload];
      }
    },
  },
});

export const {
  setNewVehicleRegistratioinNumber,
  setVehicleApplicationId,
  setVehicleApplicationFormData,
  clearVehicleApplicationFormData,
} = addNewVehicleSlice.actions;

export default addNewVehicleSlice.reducer;
