import { useCallback, useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Checkbox } from "antd";

import { routes } from "routes";
import { getContentOfRelevantItems } from "helpers/getContentOfRelevantItems";

import { Form } from "components/Form";
import { Field } from "components/Field";
import { Input } from "components/Input";
import { PasswordInput } from "components/PasswordInput";
import { Link } from "components/Link";
import { Button } from "components/Button";

export function CompleteRegistrationForm({ initialValues, onSubmit }) {
  const { t } = useTranslation();

  const renderForm = useCallback(
    ({ values, errors, onChange }) => {
      return (
        <>
          <Field label={t("field.email")} htmlFor="email" error={errors.email}>
            <Input
              id="email"
              name="email"
              value={values.email}
              disabled={initialValues.email?.length > 0}
              onChange={onChange}
            />
          </Field>
          <Field
            label={t("field.password")}
            htmlFor="password"
            error={errors.password}
          >
            <PasswordInput
              id="password"
              name="password"
              value={values.password}
              onChange={onChange}
            />
          </Field>
          <Field
            label={t("field.passwordConfirm")}
            htmlFor="passwordConfirm"
            error={errors.passwordConfirm}
          >
            <PasswordInput
              id="passwordConfirm"
              name="passwordConfirm"
              value={values.passwordConfirm}
              onChange={onChange}
            />
          </Field>

          <Field error={errors.acceptAgreement}>
            <Checkbox
              name="acceptAgreement"
              checked={values.acceptAgreement}
              onChange={onChange}
            >
              <Trans
                i18nKey="modalTermsNotAccepted.iReadAndAcceptTerms"
                components={{
                  termslink: (
                    <Link
                      to={routes.termsAndConditions.getURL()}
                      target="_blank"
                    />
                  ),
                }}
              />
            </Checkbox>
          </Field>

          <Field>
            <Button block type="submit" variant="primary" shape="round">
              {t("action.completeRegistration")}
            </Button>
          </Field>
        </>
      );
    },
    [t, initialValues],
  );

  const validationRules = useMemo(() => {
    return getContentOfRelevantItems([
      {
        condition: true,
        content: {
          field: "email",
          isValid: (value) => typeof value === "string" && value.length > 0,
          error: t("error.emailRequired"),
        },
      },
      {
        condition: true,
        content: {
          field: "password",
          isValid: (value) => typeof value === "string" && value.length > 0,
          error: t("error.passwordRequired"),
        },
      },
      {
        condition: true,
        content: {
          field: "password",
          isValid: (value) => typeof value === "string" && value.length > 8,
          error: t("error.passwordMustBeLonger"),
        },
      },
      {
        condition: true,
        content: {
          field: "passwordConfirm",
          isValid: (value) => typeof value === "string" && value.length > 0,
          error: t("error.passwordConfirmationRequired"),
        },
      },
      {
        condition: true,
        content: {
          field: "passwordConfirm",
          isValid: (value, form) => value === form.password,
          error: t("error.passwordsNotMatch"),
        },
      },
      {
        condition: true,
        content: {
          field: "acceptAgreement",
          isValid: (value) => value,
          error: t("error.agreementRequired"),
        },
      },
    ]);
  }, [t]);

  return (
    <Form
      initialValues={initialValues}
      validationRules={validationRules}
      onSubmit={onSubmit}
      noValidate
    >
      {renderForm}
    </Form>
  );
}
