import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { api } from "api";

import { DocumentForm } from "pages/documents/components/DocumentForm";
import {
  clearVehicleApplicationFormData,
  setVehicleApplicationFormData,
} from "store/slices/addNewVehicleSlice";

export function CreateDocumentForm({
  isEditPage = false,
  documentTypeId,
  documentTypeDetails,
  nextPage,
  skippable = true,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { vehicleApplicationId, vehicleApplicationFormData } = useSelector(
    (state) => state.addNewVehicleslice,
  );

  const [
    createNewDocumentRequest,
    {
      error: creatingError,
      isLoading: isCreating,
      isSuccess: isCreatingSucceed,
      isError: isCreatingFailed,
      reset: resetCreating,
    },
  ] = api.endpoints.createDocument.useMutation();

  useEffect(() => {
    if (isCreating) {
      message.loading({
        content: t("processing.documentSaving"),
        duration: 0,
        key: "saving_document",
      });
    } else {
      message.destroy("saving_document");
    }
  }, [isCreating, t]);

  useEffect(() => {
    if (isCreatingSucceed) {
      message.success({
        content: t("success.documentSaved"),
      });

      resetCreating();
      navigate(nextPage);
    }
  }, [isCreatingSucceed, navigate, nextPage, resetCreating, t]);

  useEffect(() => {
    if (isCreatingFailed) {
      const errorMessage = creatingError?.data?.message;

      if (errorMessage === undefined) {
        message.error({
          content: t("error.documentNotSaved"),
          duration: 8,
        });
      } else {
        message.error({
          content: t("error.documentNotSavedWithReason", {
            reason: errorMessage,
          }),
          duration: 8,
        });
      }
    }
  }, [isCreatingFailed, t, creatingError?.data?.message]);

  const currentDocument = vehicleApplicationFormData[documentTypeId];
  const isCurrentDocumentExist = Boolean(currentDocument);

  const buttonsIfDocumentNotExist = skippable
    ? [
        {
          block: true,
          variant: "primary",
          shape: "round",
          fontWeight: "bold",
          onClick: "onSave",
          loading: isCreating,
          disabled: !isEditPage || isCreating,
          children: t("action.save"),
        },
        {
          block: true,
          variant: "link",
          importance: "warning",
          fontWeight: "bold",
          shape: "round",
          to: nextPage,
          onClick: () => {
            message.warning("Data not saved");
          },
          children: t("action.skipForNow"),
        },
      ]
    : [
        {
          block: true,
          variant: "primary",
          shape: "round",
          fontWeight: "bold",
          onClick: "onSave",
          loading: isCreating,
          disabled: !isEditPage || isCreating,
          children: t("action.save"),
        },
      ];

  return (
    <DocumentForm
      isEditPage={!isCurrentDocumentExist}
      document={currentDocument}
      documentType={documentTypeDetails}
      buttons={
        isEditPage && !isCurrentDocumentExist
          ? buttonsIfDocumentNotExist
          : [
              {
                block: true,
                variant: "default",
                importance: "error",
                shape: "round",
                fontWeight: "bold",
                onClick: () => {
                  dispatch(clearVehicleApplicationFormData(documentTypeId));
                },
                children: t("action.clear"),
              },
              {
                block: true,
                variant: "primary",
                shape: "round",
                fontWeight: "bold",
                to: nextPage,
                children: t("action.next"),
              },
            ]
      }
      onSave={({ extraInfo, expireAt, files }) => {
        createNewDocumentRequest({
          type: documentTypeId,
          extraInfo,
          expireAt,
          files,
          documentableId: vehicleApplicationId,
          documentableType: "vehicle_application",
        }).then(({ data }) => {
          dispatch(
            setVehicleApplicationFormData({
              documentType: documentTypeId,
              data: {
                documentableId: vehicleApplicationId,
                type: documentTypeId,
                files: data?.data?.files,
                id: data?.data?.id,
                extra_info: extraInfo,
                expire_at: expireAt,
              },
            }),
          );
        });
      }}
    />
  );
}
