import { api } from "api";
import { routes } from "routes";
import { useTranslation } from "react-i18next";
import { LoadingComp } from "components/LoadingComp";
import { useSelectedFleet } from "hooks/useSelectedFleet";
import { Title } from "components/Title";
import { DocumentListItem } from "../documents/components/DocumentListItem";
import { DocumentStatus } from "../documents/statusUtils";
import { CTAButton } from "./CTAButton/CTAButton";
import styles from "./MainComponent.module.scss";

function getStatusForVehicleApplicationDocument(status) {
  if (["in_progress", "new"].includes(status)) {
    return DocumentStatus.IN_REVIEW;
  }
  return null;
}

export const MainComponent = () => {
  const { t } = useTranslation();
  const { selectedDriverProfile } = useSelectedFleet();

  const {
    data: vehicleApplicationData,
    isLoading: isVehicleApplicationLoading,
  } = api.endpoints.getVehicleApplications.useQuery({
    driverId: selectedDriverProfile.id,
  });

  const newVehicles =
    vehicleApplicationData?.data
      .filter(({ submitted_at }) => submitted_at !== null)
      .filter(({ status }) => status !== "approved") || [];

  if (isVehicleApplicationLoading) {
    return <LoadingComp loading fullScreen />;
  }

  return (
    <>
      <div className={styles.vehicleDocumentsListWrapper}>
        <Title level={2}>{t("pageDocument.addNewVehicles")}</Title>
        {newVehicles.map((vehicle) => (
          <div className={styles.vehicleDocumentsItemWrapper} key={vehicle.id}>
            <DocumentListItem
              newStatusIcon={true}
              type={vehicle.id}
              status={getStatusForVehicleApplicationDocument(vehicle.status)}
              title={vehicle.vehicle_registration}
              editLink={routes.reviewNewVehicle.getURL(vehicle.id)}
            />
          </div>
        ))}
      </div>
      <div className={styles.ctaBtnWrapper}>
        <CTAButton />
      </div>
    </>
  );
};
