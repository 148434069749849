import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format, isPast } from "date-fns";

import { DocumentStatus } from "../../statusUtils";

import { Badge, BadgeList } from "../Badge";
import { DocumentStatusIcon } from "../DocumentStatusIcon";
import { NewDocumentStatusIcon } from "../NewDocumentStatusIcon";

import styles from "./DocumentListItem.module.scss";
import { useDocumentItemExternalLink } from "pages/documents/useDocumentItemExternalLink";

export function DocumentListItem({
  type,
  title,
  content,
  updateBefore,
  status,
  viewLink,
  editLink,
  newStatusIcon = false,
}) {
  const { t } = useTranslation();
  const { flag, handleClickExternalLink } = useDocumentItemExternalLink();

  const isDeadlineMissed = isPast(new Date(updateBefore));

  return (
    <div id={type} aria-label={type} className={styles.documentListItem}>
      <div className={styles.row}>
        {newStatusIcon ? (
          <NewDocumentStatusIcon status={status} />
        ) : (
          <DocumentStatusIcon status={status} />
        )}

        <div className={styles.content}>
          <h3 className={styles.title}>{title}</h3>
          {flag ? (
            <div
              dangerouslySetInnerHTML={{ __html: content }}
              onClick={handleClickExternalLink}
            ></div>
          ) : (
            <p className={styles.text}>{content}</p>
          )}
        </div>

        {viewLink && (
          <Link to={viewLink} className={styles.link}>
            {t("action.view")}
          </Link>
        )}

        {editLink && (
          <Link to={editLink} className={styles.link}>
            {t("action.edit")}
          </Link>
        )}
      </div>

      {(status === DocumentStatus.REQUIRES_INPUT ||
        status === DocumentStatus.REJECTED) &&
      updateBefore !== undefined &&
      updateBefore !== null ? (
        <div className={styles.badges}>
          <BadgeList>
            {isDeadlineMissed ? (
              <Badge status="danger">
                {t("componentDocumentBadge.deadlineMissed")}
              </Badge>
            ) : (
              <Badge>
                {t("componentDocumentBadge.updateBeforeDate", {
                  date: format(new Date(updateBefore), "MMMM dd, yyyy"),
                })}
              </Badge>
            )}
          </BadgeList>
        </div>
      ) : null}
    </div>
  );
}
