import clsx from "clsx";

import { Label } from "components/Label";
import { Small } from "components/Small";
import { Error } from "components/Error";

import styles from "./Field.module.scss";

export function Field({
  label = null,
  htmlFor = null,
  description = null,
  error = null,
  children = null,
  dense = false,
  className = "",
}) {
  return (
    <div
      className={clsx(
        styles.field,
        {
          [styles.extraSpace]: !dense,
        },
        className,
      )}
    >
      {label && (
        <div className={styles.captions}>
          <Label htmlFor={htmlFor}>{label}</Label>
        </div>
      )}

      <div>{children}</div>

      {error && (
        <div className={styles.description}>
          <Error>{error}</Error>
        </div>
      )}

      {description && (
        <div className={styles.description}>
          <Small>{description}</Small>
        </div>
      )}
    </div>
  );
}
