import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import keyBy from "lodash/keyBy";

import { api } from "api";
import { routes } from "routes";
import { useSelectedFleet } from "hooks/useSelectedFleet";

import { SingleScreen } from "components/SingleScreen";
import { LoadingComp } from "components/LoadingComp";

import { getReadonlyDocument } from "../getReadonlyDocument";
import { getLatestDocumentByStatusByType } from "../getLatestDocumentByStatusByType";
import { DocumentType } from "../DocumentType";
import { DocumentForm } from "./DocumentForm";

export function DriverDocumentForm({
  isEditPage,
  documentTypeId,
  documentTypeDetails,
}) {
  const { t } = useTranslation();
  const [documentData, setDocumentData] = useState(null);

  const predefinedDocumentTypeIds = Object.values(DocumentType);
  const isPredefined = predefinedDocumentTypeIds.includes(documentTypeId);

  const { selectedDriverProfile } = useSelectedFleet();
  const currentDriverProfileId = selectedDriverProfile?.id;

  const { custom_document_type_deadlines: customDocumentTypeDeadlines = [] } =
    selectedDriverProfile;

  const customDocumentTypeDeadlinesByCustomDocumentTypeId = keyBy(
    customDocumentTypeDeadlines,
    "custom_document_type_id",
  );

  const {
    data: driverDocumentsResponse,
    isLoading: areDriverDocumentsLoading,
  } = api.endpoints.getDriverDocuments.useQuery(currentDriverProfileId, {
    skip: !currentDriverProfileId,
  });

  const documents = driverDocumentsResponse?.data;
  const latestDocumentByStatusByType =
    getLatestDocumentByStatusByType(documents);
  const lastDocumentOfCurrentType =
    latestDocumentByStatusByType?.[documentTypeId]?.last;

  const currentDocument = isEditPage
    ? lastDocumentOfCurrentType
    : getReadonlyDocument({
        document: lastDocumentOfCurrentType,
        documentSchema: documentTypeDetails,
        context: { currentDriverProfile: selectedDriverProfile, documents },
      });

  useEffect(() => {
    setDocumentData(currentDocument);
  }, [currentDocument]);

  const [
    createNewDocumentRequest,
    {
      error: creatingError,
      isLoading: isCreating,
      isSuccess: isCreatingSucceed,
      isError: isCreatingFailed,
      reset,
    },
  ] = api.endpoints.createDocument.useMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (isCreating) {
      message.loading({
        content: t("processing.documentSaving"),
        duration: 0,
        key: "saving_document",
      });
    }
  }, [isCreating, t]);

  useEffect(() => {
    if (isCreatingFailed) {
      const errorMessage = creatingError?.data?.message;

      if (errorMessage === undefined) {
        message.error({
          content: t("error.documentNotSaved"),
          duration: 8,
        });
      } else {
        message.error({
          content: t("error.documentNotSavedWithReason", {
            reason: errorMessage,
          }),
          duration: 8,
        });
      }
    }

    if (isCreatingSucceed) {
      message.success({
        content: t("success.documentSaved"),
      });

      navigate(routes.documents.getURL());
    }

    if (isCreatingSucceed || isCreatingFailed) {
      reset();
      message.destroy("saving_document");
    }
  }, [isCreatingSucceed, isCreatingFailed, navigate, reset, creatingError, t]);

  if (areDriverDocumentsLoading) {
    return <LoadingComp loading fullScreen />;
  }

  const saveButton = {
    block: true,
    variant: "primary",
    shape: "round",
    fontWeight: "bold",
    onClick: "onSave",
    loading: isCreating,
    disabled: isCreating,
    children: t("action.save"),
  };
  const clearButton = {
    block: true,
    variant: "default",
    importance: "error",
    shape: "round",
    fontWeight: "bold",
    onClick: () => {
      setDocumentData(null);
    },
    children: t("action.clear"),
  };

  const backButton = {
    block: true,
    variant: "secondary",
    shape: "round",
    fontWeight: "bold",
    to: routes.documents.getURL(),
    children: t("action.back"),
  };

  const buttonsIfEditPage = documentData
    ? [clearButton, saveButton, backButton]
    : [saveButton, backButton];

  return (
    <SingleScreen fullHeight>
      <DocumentForm
        isEditPage={isEditPage}
        document={documentData}
        documentType={documentTypeDetails}
        updateBefore={
          documentTypeDetails?.with_deadline &&
          latestDocumentByStatusByType?.[documentTypeId]?.approved ===
            undefined &&
          customDocumentTypeDeadlinesByCustomDocumentTypeId[documentTypeId] !==
            undefined
            ? customDocumentTypeDeadlinesByCustomDocumentTypeId[documentTypeId]
                ?.date
            : undefined
        }
        buttons={
          isEditPage
            ? buttonsIfEditPage
            : [
                {
                  block: true,
                  variant: "primary",
                  shape: "round",
                  fontWeight: "bold",
                  to: routes.documents.getURL(),
                  children: t("action.back"),
                },
              ]
        }
        onSave={({ extraInfo, files, expireAt }) => {
          createNewDocumentRequest({
            type: isPredefined ? documentTypeId : DocumentType.CUSTOM_DOCUMENT,
            customDocumentTypeId: isPredefined ? undefined : documentTypeId,
            extraInfo,
            files,
            documentableId: currentDriverProfileId,
            documentableType: "driver_profile",
            expireAt,
          });
        }}
      />
    </SingleScreen>
  );
}
