import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import { api } from "api";
import { routes } from "routes";
import { useAuth } from "hooks/useAuth";

import { FieldType } from "../FieldType";
import { DocumentType } from "../DocumentType";
import { encodeValue } from "../converters";

import { VehicleDetailsOnboardingForm } from "./VehicleDetailsOnboardingForm";
import { useQueryCustomDocumentTypes } from "../useQueryDocumentTypes";

export function VehicleDetailsOnboardingPage() {
  const { t } = useTranslation();

  const { fleetSlug } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  const { data: marketingData } =
    api.endpoints.getMarketingPageByFleetSlug.useQuery({
      fleetSlug,
    });

  const { fleet_id: fleetId } = marketingData?.data ?? {};
  const { data: fleetPublicData } =
    api.endpoints.getFleetPublicInfoById.useQuery(
      {
        fleetId,
      },
      { skip: fleetId === undefined || fleetId === null },
    );

  const {
    predefinedDriverDocumentTypes,
    customApplicationDriverDocumentTypes,
    customApplicationOnlyDocumentTypes,
    predefinedVehicleDocumentTypes,
    customApplicationVehicleDocumentTypes,
  } = useQueryCustomDocumentTypes({ currentCompanyId: fleetId });

  const documentsToShow = [
    ...predefinedDriverDocumentTypes,
    ...customApplicationDriverDocumentTypes,
    ...customApplicationOnlyDocumentTypes,
    ...predefinedVehicleDocumentTypes,
    ...customApplicationVehicleDocumentTypes,
  ];

  const documentIndex = documentsToShow.findIndex(
    ({ id }) => id === DocumentType.VEHICLE_DETAILS,
  );
  const nextDocument = documentsToShow[documentIndex + 1]?.id || "submit"; // If VEHICLE_DETAILS is lates in documentsToShow, next step should be 'submit'

  if (documentIndex === -1) {
    /**
     * This error should not happend,
     * because we allways should have VEHICLE_DETAILS visible.
     */
    throw new Error("Vehicle document was not found!");
  }
  const vehicleLookupService =
    fleetPublicData?.data?.vehicle_lookup_service ?? "manual";

  const driverApplication = user?.driver_applications?.find(
    (application) => application?.fleet_id === fleetId,
  );

  const vehicleDetailsDocument = useMemo(
    () => ({
      registration: driverApplication?.vehicle_registration,
      area: driverApplication?.preferred_work_area,
      hasOwnVehicle: Boolean(driverApplication?.metadata?.has_own_vehicle),
      year: driverApplication?.manual_vehicle_look_up?.year,
      make: driverApplication?.manual_vehicle_look_up?.vehicle_make,
      model: driverApplication?.manual_vehicle_look_up?.vehicle_model,
      colour: driverApplication?.manual_vehicle_look_up?.colour,
      emissions: driverApplication?.manual_vehicle_look_up?.co2_emissions,
      nctExpiry: driverApplication?.manual_vehicle_look_up?.nct_expiry,
      roadTaxExpiry: driverApplication?.manual_vehicle_look_up?.road_tax_expiry,
    }),
    [driverApplication],
  );

  const [
    patchDriverApplication,
    {
      data: patchDriverApplicationData,
      isLoading: isPatching,
      isSuccess: isPatchingSucceed,
      isError: isPatchingFailed,
      reset,
    },
  ] = api.endpoints.patchDriverApplication.useMutation();

  const [postManualVehicleLookUp, { isLoading: isSavingVehicleData }] =
    api.endpoints.postManualVehicleLookUp.useMutation();

  const isSaving = isPatching || isSavingVehicleData;

  useEffect(() => {
    if (isSaving) {
      message.loading({
        content: t("processing.sendingVehicleDetails"),
        duration: 0,
        key: "vehicle_details_loading",
      });
    } else {
      message.destroy("vehicle_details_loading");
    }
  }, [isSaving, t]);

  useEffect(() => {
    if (isPatchingFailed) {
      message.error({
        content: t("error.vehicleDetailsNotSent"),
      });
    }

    if (isPatchingSucceed) {
      message.success({
        content: t("success.vehicleDetailsSent"),
      });
    }

    if (isPatchingSucceed || isPatchingFailed) {
      reset();
      message.destroy("vehicle_details_loading");
    }
  }, [isPatchingSucceed, isPatchingFailed, navigate, reset, t]);

  useEffect(() => {
    /**
     * Navigate to the "Application is submitted" page if
     * the vehicle details was patched successfully and
     * the submitted `vehicle_registration` is `null` (means the driver has no
     * own vehicle).
     */

    if (
      isPatchingSucceed &&
      patchDriverApplicationData?.data?.vehicle_registration === null
    ) {
      navigate(
        routes.onboardingDocument.getURL({
          fleetSlug,
          documentTypeId: "submit",
        }),
      );
    }

    if (
      isPatchingSucceed &&
      patchDriverApplicationData?.data?.vehicle_registration !== null
    ) {
      navigate(
        routes.onboardingDocument.getURL({
          fleetSlug,
          documentTypeId: nextDocument,
        }),
      );
    }
  }, [
    isPatchingSucceed,
    patchDriverApplicationData?.data?.vehicle_registration,
    fleetSlug,
    navigate,
  ]);

  return (
    <VehicleDetailsOnboardingForm
      vehicleLookupService={vehicleLookupService}
      document={vehicleDetailsDocument}
      buttons={[
        {
          block: true,
          variant: "primary",
          shape: "round",
          fontWeight: "bold",
          onClick: "onSave",
          children: t("action.save"),
        },
        {
          block: true,
          variant: "link",
          importance: "warning",
          fontWeight: "bold",
          shape: "round",
          to:
            driverApplication?.vehicle_registration === null
              ? routes.onboardingDocument.getURL({
                  fleetSlug,
                  documentTypeId: "submit",
                })
              : routes.onboardingDocument.getURL({
                  fleetSlug,
                  documentTypeId: nextDocument,
                }),
          onClick: () => {
            message.warning(t("error.dataNotSaved"));
          },
          children: t("action.skipForNow"),
        },
      ]}
      onSave={(form) => {
        async function internal() {
          if (vehicleLookupService === "manual") {
            await postManualVehicleLookUp({
              entity_id: driverApplication?.id,
              vehicle_make: encodeValue(form.make, FieldType.TEXT),
              year: encodeValue(form.year, FieldType.NUMBER_INTEGER),
              vehicle_model: encodeValue(form.model, FieldType.TEXT),
              colour: encodeValue(form.colour, FieldType.TEXT),
              co2_emissions: encodeValue(form.emissions, FieldType.TEXT),
              nct_expiry: encodeValue(form.nctExpiry, FieldType.DATETIME),
              road_tax_expiry: encodeValue(
                form.roadTaxExpiry,
                FieldType.DATETIME,
              ),
            });
          }

          patchDriverApplication({
            driverApplicationId: driverApplication?.id,
            registration: form.hasOwnVehicle ? form.registration : null,
            area: form.area,
            metadata: {
              has_own_vehicle: form.hasOwnVehicle,
            },
          });
        }

        internal();
      }}
    />
  );
}
