import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./axiosBaseQuery.js";

import { TagTypes } from "./TagTypes";

import { connect as connectAuth } from "./endpoints/auth";
import { connect as connectUsers } from "./endpoints/users";
import { connect as connectDriverApplications } from "./endpoints/driverApplications";
import { connect as connectNotifications } from "./endpoints/notifications";
import { connect as connectDocuments } from "./endpoints/documents";
import { connect as connectFleets } from "./endpoints/fleets";
import { connect as connectDvla } from "./endpoints/dvla";
import { connect as connectInvite } from "./endpoints/invite";
import { connect as connectReports } from "./endpoints/reports";
import { connect as connectDashboard } from "./endpoints/dashboard";
import { connect as connectDrivers } from "./endpoints/drivers";
import { connect as connectVehicleApplication } from "./endpoints/vehicleApplication";
import { connect as connectBookings } from "./endpoints/bookings";
import { connect as connectCustomDocumentTypes } from "./endpoints/customDocumentTypes";
import { connect as connectPayouts } from "./endpoints/payouts";
import { connect as connectUserAgreementAcceptances } from "./endpoints/userAgreementAcceptances";

export const api = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQuery({ baseUrl: "/" }),
  tagTypes: Object.values(TagTypes),
  endpoints: () => ({}),
});

connectAuth(api);
connectUsers(api);
connectDriverApplications(api);
connectNotifications(api);
connectDocuments(api);
connectFleets(api);
connectDvla(api);
connectInvite(api);
connectReports(api);
connectDashboard(api);
connectDrivers(api);
connectVehicleApplication(api);
connectBookings(api);
connectCustomDocumentTypes(api);
connectPayouts(api);
connectUserAgreementAcceptances(api);
