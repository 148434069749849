import React, { useEffect, useState } from "react";
import { Button, Layout, Radio } from "antd";
import { createPortal } from "react-dom";
import { ReactComponent as CircleCloseIcon } from "../../assets/icons/Sidebar/circle_close.svg";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Searchbar } from "components/Searchbar";

const { Sider } = Layout;

export const CompanySelectorDesktopDrawer = ({
  open,
  setOpen,
  onSearch,
  filteredCompanies,
  selectedCompany,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const [locallySelectedCompany, setLocallySelectedCompany] =
    useState(selectedCompany);

  useEffect(() => {
    setLocallySelectedCompany(selectedCompany);
  }, [selectedCompany]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    onSubmit(locallySelectedCompany?.id);
    handleClose();
  };

  return (
    <>
      {open &&
        createPortal(
          <div onClick={handleClose} className="sider-backdrop" />,
          document.body,
        )}
      {createPortal(
        <Sider
          collapsible
          theme="light"
          collapsed={!open}
          breakpoint={"lg"}
          trigger={null}
          onCollapse={handleClose}
          collapsedWidth={0}
          className="company-selector-desktop"
          width={320}
        >
          <div className="company-selector-desktop-wrapper">
            <div className="menu-wrapper">
              <p>{t("componentSelectFleet.changeSelection")}</p>
              <CircleCloseIcon onClick={() => setOpen(false)} />
            </div>
            <div className="company-searchbar-wrapper">
              <Searchbar
                onPressEnter={onSearch}
                onChange={onSearch}
                placeholder={t("componentSelectFleet.searchPlaceholder")}
                allowClear
                prefix={<SearchOutlined />}
              />
            </div>
            <div className="drawer-company-list-wrapper">
              {filteredCompanies.slice(0, 15).map((company) => {
                const isSelected = locallySelectedCompany?.id === company?.id;
                return (
                  <div
                    onClick={() => setLocallySelectedCompany(company)}
                    key={company?.id}
                  >
                    <Radio checked={isSelected} />
                    <p>{company.name}</p>
                  </div>
                );
              })}
            </div>
            <Button onClick={handleSubmit}>{t("action.save")}</Button>
          </div>
        </Sider>,
        document.getElementById("root"),
      )}
    </>
  );
};
