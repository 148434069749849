import { Input } from "antd";
import clsx from "clsx";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import "./Searchbar.scss";

export const Searchbar = ({
  placeholder = "",
  prefix = <SearchIcon />,
  allowClear = false,
  className = "",
  onPressEnter = () => {},
  onChange = () => {},
}) => {
  return (
    <Input
      className={clsx("custom-searchbar", className)}
      placeholder={placeholder}
      prefix={prefix}
      allowClear={allowClear}
      onPressEnter={onPressEnter}
      onChange={onChange}
    />
  );
};
