import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
const { Option } = Select;

export const SelectAnotherCompany = ({
  onFleetChange,
  selectedFleet,
  allFleets,
}) => {
  const { t } = useTranslation();

  return (
    <div className="select-another-company-wrapper">
      <h3 className="select-another-profile">
        {t("componentSelectAnotherFleet.selectAnotherProfile")}
      </h3>
      <Select
        onChange={onFleetChange}
        value={selectedFleet.id}
        className="select-another-profile-selector"
        popupClassName="confirmation-modal-select"
      >
        {allFleets.map((fleet) => (
          <Option key={fleet.id} value={fleet.id}>
            {fleet.name}
          </Option>
        ))}
      </Select>
    </div>
  );
};
