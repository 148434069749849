import { Modal } from "antd";
import "./ConfirmationModal.scss";

export const ConfirmationModal = ({
  visible = true,
  onConfirm = () => {},
  onCancel = () => {},
  confirmLoading = false,
  title = "",
  closable = false,
  footer,
  className = "",
  zIndex = 1500,
  children,
  okText,
  cancelText,
}) => {
  return (
    <Modal
      visible={visible}
      onOk={onConfirm}
      onCancel={onCancel}
      destroyOnClose
      confirmLoading={confirmLoading}
      title={title}
      closable={closable}
      footer={footer}
      wrapClassName={`confirm-modal-container ${className}`.trim()}
      zIndex={zIndex}
      okText={okText}
      cancelText={cancelText}
      centered
    >
      {children}
    </Modal>
  );
};

export default ConfirmationModal;
