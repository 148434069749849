import { configureStore } from "@reduxjs/toolkit";
import companiesReducer from "./slices/companies";
import earningsReducer from "./slices/driverEarnings";
import earningsFiltersReducer from "./slices/earningsFilters";
import bookingFilterSliceReducer from "./slices/bookingFilters";
import menuReducer from "./slices/menuSlice";
import addNewVehicleReducer from "./slices/addNewVehicleSlice";
import onboardingReducer from "./slices/onboardingSlice";
import { selectedFleetIdSlice } from "./slices/selectedFleetIdSlice";
import { launchDarklySlice } from "./slices/launchDarkly";
import { api } from "api";
import { syncSelectedFleetWithLocalStorageMiddleware } from "./syncSelectedFleetWithLocalStorageMiddleware";

export const store = configureStore({
  reducer: {
    companies: companiesReducer,
    earnings: earningsReducer,
    earningsFilters: earningsFiltersReducer,
    bookingFilters: bookingFilterSliceReducer,
    onboardingSlice: onboardingReducer,
    [api.reducerPath]: api.reducer,
    menuSlice: menuReducer,
    addNewVehicleslice: addNewVehicleReducer,
    selectedFleetId: selectedFleetIdSlice.reducer,
    launchDarkly: launchDarklySlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      api.middleware,
      syncSelectedFleetWithLocalStorageMiddleware.middleware,
    ),
});
