import { TagTypes } from "../TagTypes";

export function connect(apiSlice) {
  apiSlice.injectEndpoints({
    overrideExisting: false,

    endpoints: (build) => ({
      postDriverApplication: build.mutation({
        invalidatesTags: [TagTypes.ME],
        query: (data) => ({
          data,
          url: "driver-applications",
          method: "POST",
        }),
      }),

      patchDriverApplication: build.mutation({
        invalidatesTags: [TagTypes.ME],
        query: ({
          driverApplicationId,
          registration,
          area,
          expireAt,
          status,
          metadata,
        }) => ({
          url: `driver-applications/${driverApplicationId}`,
          method: "PATCH",
          data: {
            vehicle_registration: registration,
            preferred_work_area: area,
            council_compliance: expireAt,
            status,
            metadata,
          },
        }),
      }),

      postManualVehicleLookUp: build.mutation({
        query: (data) => ({
          method: "POST",
          url: "manual-vehicle-look-up",
          data,
        }),
      }),
      postManualVehicleLookUpWithActiveFeatures: build.mutation({
        query: (data) => ({
          headers: {
            "Active-Features": "release-add-new-vehicle",
          },
          method: "POST",
          url: "manual-vehicle-look-up",
          data,
        }),
      }),
    }),
  });
}
